// const getEnv = () => {
// 	switch (process.env.NODE_ENV) {
// 		case 'development':
// 			return 'https://uatapi.zunocabs.com'
// 		case 'production':
// 			return 'https://uatapi.zunocabs.com'
// 		case 'test':
// 			return 'https://uatapi.zunocabs.com'
// 		default:
// 			break;
// 	}
// }

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return 'https://api.zunocabs.com'
		case 'production':
			return 'https://api.zunocabs.com'
		case 'test':
			return 'https://api.zunocabs.com'
		default:
			break;
	}
}


// const getPublic = () => {
// 	switch (process.env.NODE_ENV) {
// 		case 'development':
// 			return 'http://localhost:3000'
// 		case 'production':
// 			return 'http://localhost:3000'
// 		case 'test':
// 			return 'http://localhost:3000'
// 		default:
// 			break;
// 	}
// }

export const base_url = getEnv();
// export const public_url = getPublic();
