import React, { Suspense } from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Views from 'views';
import 'App.css';

function App() {

     return (
          <Suspense fallback={"Loading ...."}>
               <Router>
                    <Switch>
                         <Route path="/" component={Views} />
                    </Switch>
               </Router>
               <Toaster position="top-center" reverseOrder={false}/>
          </Suspense>
     )
}

export default App;
