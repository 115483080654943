import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { APP_PREFIX } from 'configs/AppConfig';
import PageLoader from "views/components/layout-components/PageLoader";

export const AppViews = () => {
    return (
        <Suspense fallback={(<PageLoader />)}>
            <Switch>
                <Route path={`${APP_PREFIX}/dashboard`} component={lazy(() => import(`./dashboard`))} />
                <Route path={`${APP_PREFIX}/rides`} component={lazy(() => import(`./rides`))} />
                <Route path={`${APP_PREFIX}/drivers`} component={lazy(() => import(`./drivers`))} />
                <Route path={`${APP_PREFIX}/live-tracking`} component={lazy(() => import(`./live-tracking`))} />
                <Route path={`${APP_PREFIX}/users`} component={lazy(() => import(`./users`))} />
                <Route path={`${APP_PREFIX}/benifits`} component={lazy(() => import(`./benifits`))} />
                <Route path={`${APP_PREFIX}/staffs`} component={lazy(() => import(`./staffs`))} />
                <Route path={`${APP_PREFIX}/events`} component={lazy(() => import(`./events`))} />
                <Route path={`${APP_PREFIX}/vehicles`} component={lazy(() => import(`./vehicles`))} />
                <Route path={`${APP_PREFIX}/subscription`} component={lazy(() => import(`./subscription`))} />
                <Route path={`${APP_PREFIX}/reports`} component={lazy(() => import(`./reports`))} />
                <Route path={`${APP_PREFIX}/profiles`} component={lazy(() => import(`./profiles`))} />
                <Route path={`${APP_PREFIX}/enquiry`} component={lazy(() => import(`./enquiry`))} />
                <Route path={`${APP_PREFIX}/feedbacks`} component={lazy(() => import(`./feedbacks`))} />
                <Route path={`${APP_PREFIX}/settings`} component={lazy(() => import(`./settings`))} />
                <Route component={lazy(() => import(`./pagenotfound`))} />
                <Redirect from={`${APP_PREFIX}`} to={`${APP_PREFIX}/dashboard`} />
            </Switch>
        </Suspense>
    )
}

export default AppViews;

