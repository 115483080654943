import React from 'react'
import { Switch, Route, } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AuthViews from 'views/auth-views';
import Logo from "assets/images/lk.png";
import Info from "assets/images/info.svg";

export const AuthLayout = () => {
    return (
        <div className="login-back">
            <Row>
                <Col md={2}></Col>
                <Col md={8}>
                <Row className="login-card">
                    <Col md={8} xs={8}>
                        <img src={Logo} alt="..." width="130px" className="login-logo" />
                    </Col>
                    <Col md={4} xs={4}>
                      <h6>  <img alt="..." src={Info} />  About Zuno Cabs</h6>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4} xs={12}>
                        <Switch>
                            <Route path="" component={AuthViews} />
                        </Switch>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={12}>
                        <center>
                          <p className="foot-policy"><Link to="/auth/privacy_policy">Privacy Policy </Link> |  Terms & Conditions</p>
                        </center>
                    </Col>
                </Row> 
                    </Col>
                <Col md={4}>
                
                </Col>
            </Row>
      
        </div>
    )
}

export default AuthLayout;