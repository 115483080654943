import { base_url } from "configs/EnvConfig";
import toast from "react-hot-toast";
import axios from "axios";


const TOKEN = localStorage.getItem("access_token");
// const baseURL = "https://api.zunocabs.com/";
const fetchAwsImage = async (imgPath) => {
    const data = await fetch(`${base_url}/getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => {
            if (response.status === 401) {
                window.location.reload();
            }
            return response.blob()
        })
        .then(blob => URL.createObjectURL(blob))
    return data;
}

const fetchAwsDoc = async (imgPath) => {
    const data = await fetch(`${base_url}/getFileStream?path=${imgPath}`, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
        }
    })
        .then(response => response.blob())
        .then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = "file.pdf";
            window.open(fileURL);
            toast.success("Pdf Downloaded Successfully");
            alink.click();
        });
    return data;
}

// import Cookies from "js-cookie";

// const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;

const client = axios.create({
    baseURL: "https://uatapi.zunocabs.com",
    timeout: TIMEOUT,
    headers: {
        "content-type": "application/json",
    }
});

client.interceptors.request.use(function (config) {
    return config;
});
client.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

const Map = async () => {
    const { data } = await client.get(`public/liveRideDetails`);
    return data;
}



export {
    fetchAwsImage,
    fetchAwsDoc,
    Map
};