import { useAwsImage } from "hooks/ImgHook";
import profile from "assets/images/avathar1.jpg";
import React, { Fragment, useEffect } from "react";

const PrivateImage = ({ className, privateUrl }) => {
    const { data, refetch } = useAwsImage(privateUrl);
    
    useEffect(() => {
        if (privateUrl !== undefined) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateUrl]);

    return (
        <Fragment>
            {privateUrl && data !== undefined ?
                <img className={className} src={data} alt="../" /> : <img className={className} src={profile} alt="../" loading="lazy" />
            }
        </Fragment>
    )
};

export default PrivateImage;