import React from "react";
import { Route } from "react-router-dom";
import NavBar from 'views/components/layout-components/NavBar';
import SideBar from 'views/components/layout-components/SideBar';
import AppViews from 'views/app-views';

export const AppLayout = () => {
    return (
        <div className="app-layout">
            <NavBar />
            <div className="part-card">
                <div className="sidebar-part">
                    <SideBar />
                </div>
                <div className="side-content-part">
                    <Route path="" component={AppViews} />
                </div>
            </div>
        </div>
    )
}

export default AppLayout;