import React, { useEffect } from 'react';
import { Form, Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation } from "react-query";
import ZunoInterceptor from 'configs/ZunoInterceptor';
import "assets/css/navbar.css";
import Logo from "assets/images/lk.png";
import Search from "assets/images/search.svg";
import NotifyBody from '../shared-components/filter/notifyBody';

export const NavBar = () => {
    const { mutateAsync, data: notications } = useMutation(async () => await ZunoInterceptor.getNotify("fetchNotificationsList"));
    const { mutateAsync: positionAsync, } = useMutation(async (id) => { await ZunoInterceptor.updateNotification("updateCmsNotifications", id); });

    const updateNotificationsList = (id) => {
        try {
            positionAsync(id);
            fetchNotificationsList();
        } catch (error) {
            console.log(error);
        }
    };
    const fetchNotificationsList = () => {
        try {
            mutateAsync();
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        fetchNotificationsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Navbar expand="lg">
                <Container fluid>
                    <Navbar.Brand><Link to="/"><img src={Logo} className="logo-img" width="130px" alt="" /></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link ><Link to="/">Admin CMS Panel</Link></Nav.Link>
                            <span className="search-img" style={{ visibility: "hidden" }}><img src={Search} alt="" /> </span>
                            <Form.Control type="text" placeholder="Search..." className="form-input" style={{ visibility: "hidden" }} />
                        </Nav>
                        <NotifyBody
                            data={notications?.content}
                            update={updateNotificationsList}
                            fetch={fetchNotificationsList}
                        />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar;