import { useQuery } from "react-query";
import * as imgApi from "services/imgServices";

export const useAwsImage = (imgPath) => {
    return useQuery(["AwsProfileImages", imgPath], () => imgApi.fetchAwsImage(imgPath), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}

export const useMap = () => {
    return useQuery("Maps", () => imgApi.Map(), {
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
    });
}

export const useAwsDoc = (imgPath) => {
    return useQuery(["AwsProfileDocs", imgPath], () => imgApi.fetchAwsDoc(imgPath), {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
        refetchOnReconnect: true,
    });
}
