import axios from "axios";
import { base_url } from 'configs/EnvConfig';
import moment from "moment";
import { timeFormat } from "./axios/users";

const authLogin = async (formData) => {
    try {
        const response = await axios.post(base_url + "/login", formData);
        return response;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};
const dateFormat = (date) =>
    moment(date).format("YYYY-MM-DD");
const addVehicle = async (formData, id) => {
    try {
        const response = await axios.post(base_url + `/public/addNewVehicleForDriver/${id}`, formData);
        return response;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) {
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};
const updateVehicle = async (formData, id) => {
    try {
        const response = await axios.post(base_url + `/public/updateVehicleForDriver/${id}`, formData);
        return response;
    } catch (error) {
        if (error.response) { // get response with a status code not in range 2xx
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else { // Something wrong in setting up the request
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};
const date = dateFormat(new Date());
const weekDate = dateFormat(moment().subtract(7, "days"));
const monthDate = dateFormat(moment().subtract(30, "days"));
const yearDate = dateFormat(moment().subtract(7, "months"));
let data;

const chartData = {
    0: ["12 AM", "1 AM", "2 AM", "3 AM"],
    1: ["4 AM", "5 AM", "6 AM"],
    2: ["7 AM", "8 AM", "9 AM"],
    3: ["10 AM", "11 AM", "12 PM"],
    4: ["01 PM", "02 PM", "03 PM"],
    5: ["04 PM", "05 PM", "06 PM"],
    6: ["07 PM", "08 PM", "09 PM"],
    7: ["10 PM", "11 PM", "12 PM"]
};
function getChartData(user) {
    const userChartObj = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0
    };
    if (user !== undefined && user !== null) {
        user !== undefined && user !== null && user.map((item) => {
            let date1 = timeFormat(item);
            if (Object.values(chartData[0]).includes(date1)) {
                userChartObj["0"] += 1;
            } else if (Object.values(chartData[1]).includes(date1)) {
                userChartObj["1"] += 1;
            } else if (Object.values(chartData[2]).includes(date1)) {
                userChartObj["2"] += 1;
            } else if (Object.values(chartData[3]).includes(date1)) {
                userChartObj["3"] += 1;
            } else if (Object.values(chartData[4]).includes(date1)) {
                userChartObj["4"] += 1;
            } else if (Object.values(chartData[5]).includes(date1)) {
                userChartObj["5"] += 1;
            } else if (Object.values(chartData[6]).includes(date1)) {
                userChartObj["6"] += 1;
            } else if (Object.values(chartData[7]).includes(date1)) {
                userChartObj["7"] += 1;
            }
        })
    }
    return userChartObj;
}
function getDriverChatData(user) {
    const driverChartObj = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0
    };
    if (user !== undefined && user !== null) {
        user !== undefined && user !== null && user.map((item, index) => {
            let date1 = timeFormat(item);
            if (Object.values(chartData[0]).includes(date1)) {
                driverChartObj["0"] += 1;
            } else if (Object.values(chartData[1]).includes(date1)) {
                driverChartObj["1"] += 1;
            } else if (Object.values(chartData[2]).includes(date1)) {
                driverChartObj["2"] += 1;
            } else if (Object.values(chartData[3]).includes(date1)) {
                driverChartObj["3"] += 1;
            } else if (Object.values(chartData[4]).includes(date1)) {
                driverChartObj["4"] += 1;
            } else if (Object.values(chartData[5]).includes(date1)) {
                driverChartObj["5"] += 1;
            } else if (Object.values(chartData[6]).includes(date1)) {
                driverChartObj["6"] += 1;
            } else if (Object.values(chartData[7]).includes(date1)) {
                driverChartObj["7"] += 1;
            }
        })
    }
    return driverChartObj;
}
function getRideChatData(user) {
    const rideChartObj = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0
    };
    if (user !== undefined && user !== null) {
        user !== undefined && user !== null && user.map((item, index) => {
            let date1 = timeFormat(item);
            if (Object.values(chartData[0]).includes(date1)) {
                rideChartObj["0"] += 1;
            } else if (Object.values(chartData[1]).includes(date1)) {
                rideChartObj["1"] += 1;
            } else if (Object.values(chartData[2]).includes(date1)) {
                rideChartObj["2"] += 1;
            } else if (Object.values(chartData[3]).includes(date1)) {
                rideChartObj["3"] += 1;
            } else if (Object.values(chartData[4]).includes(date1)) {
                rideChartObj["4"] += 1;
            } else if (Object.values(chartData[5]).includes(date1)) {
                rideChartObj["5"] += 1;
            } else if (Object.values(chartData[6]).includes(date1)) {
                rideChartObj["6"] += 1;
            } else if (Object.values(chartData[7]).includes(date1)) {
                rideChartObj["7"] += 1;
            }
        })
    }
    return rideChartObj;
}
function getThisWeekDates() {
    var weekDates = [];

    for (var i = 0; i <= 6; i++) {
        weekDates.push(moment().subtract(i, "day").format("ddd"));
    }
    return weekDates;
}
function getThisMonthDates() {
    var weekDates = [];
    for (var i = 30; i >= 0; i = i - 5) {
        weekDates.push(moment().subtract(i, "day").format("MMM Do YY"));
    }
    return weekDates;
}
const setDetails = (val) => {
    data = val;
}
const getDetails = () => {
    return data;
}
const authForgotPassword = async (key, { id }) => {
    const { data } = await axios.get(base_url + "/forgot-password");
    return data;
};
const S3_ROOT = "https://cf-zuno-cabs-stage.s3.ap-southeast-1.amazonaws.com/";

const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

const exportToJson = (dataCsv, fileName) => {
    const fileURL = window.URL.createObjectURL(dataCsv);
    // Setting various property values
    let alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = 'SamplePDF.pdf';
    alink.click();
    // downloadFile({
    //     data: dataCsv,
    //     fileName: fileName,
    //     fileType: 'pdf',
    // })
}
function camelize(str) {
    return str?.toLowerCase()
        .split(' ')
        .map(
            (word) => word[0]?.toUpperCase() + word?.slice(1)
        )
        .join(' ');
}

function shortStr(str) {
    return str?.toLowerCase()
        .split(' ')
        .map(
            (word) => word[0]?.toUpperCase()
        )
        .join(' ');
}


export {
    authLogin,
    authForgotPassword,
    S3_ROOT,
    getDetails,
    setDetails,
    dateFormat,
    getDriverChatData,
    getRideChatData,
    camelize,
    date,
    weekDate,
    monthDate, yearDate, getThisMonthDates, getThisWeekDates, addVehicle, updateVehicle,
    getChartData,
    exportToJson
};