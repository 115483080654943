import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AUTH_PREFIX } from 'configs/AppConfig'
import PageLoader from "views/components/layout-components/PageLoader";

export const AuthViews = () => {
    return (
        <Suspense fallback={(<PageLoader />)}>
            <Switch>
                <Route path={`${AUTH_PREFIX}/login`} component={lazy(() => import(`./auth/Login`))} />
                <Route path={`${AUTH_PREFIX}/register`} component={lazy(() => import(`./auth/Register`))} />
                <Route path={`${AUTH_PREFIX}/privacy_policy`} component={lazy(() => import(`./privacypolicy/privacy`))} />
                <Redirect from={`${AUTH_PREFIX}`} to={`${AUTH_PREFIX}/login`} />
            </Switch>
        </Suspense>
    )
}

export default AuthViews;

