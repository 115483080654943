import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
// import { useStore } from "store";
import AppLayout from "views/layouts/app-layouts";
import AuthLayout from 'views/layouts/auth-layouts';
import { APP_PREFIX, AUTH_PREFIX } from 'configs/AppConfig';

function AppInterceptor({ children, prefix, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => isAuthenticated ? (
                children
            ) : (
                <Redirect to={{ pathname: prefix, state: { from: location } }} />
            )
            }
        />
    );
}

export const Views = (props) => {
    // const { isAuthorized } = useStore();
    const { location } = props;
    let PREFIX = localStorage.getItem("access_token") ? APP_PREFIX : AUTH_PREFIX;

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={localStorage.getItem("access_token") ? `${APP_PREFIX}/dashboard` : AUTH_PREFIX} />
                {localStorage.getItem("access_token") ? <AppLayout /> : <AuthLayout />}
            </Route>
            <Route path={AUTH_PREFIX}>
                <AuthLayout />
            </Route>
            <AppInterceptor prefix={PREFIX} path={APP_PREFIX} isAuthenticated={localStorage.getItem("access_token")}>
                <AppLayout location={location} />
            </AppInterceptor>
        </Switch>
    )
}

export default Views;