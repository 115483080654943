import Card from 'react-bootstrap/Card';
import "../../../assets/css/sidebar.css";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import toast from 'react-hot-toast';
import Dashboard from "../../../assets/images/dash.svg";
import Car from "../../../assets/images/car.svg";
import Soft from "../../../assets/images/soft.svg";
import User from "../../../assets/images/user.svg";
import Staff from "../../../assets/images/staff.svg";
import Vehicle from "../../../assets/images/vehicle.svg";
import Report from "../../../assets/images/report.svg";
import Profile from "../../../assets/images/profile.svg";
import Setting from "../../../assets/images/setting.svg";
import Subs from "assets/images/subs.png";
import Logout from "../../../assets/images/logout.svg";
import Rides from "../../../assets/images/trips.svg";
import MapIcon from "../../../assets/images/map-icon.png";
import Ben from "../../../assets/images/ben.svg";
import { APP_PREFIX } from 'configs/AppConfig';

export const SideBar = () => {
    const handleLogout = () => {
        Cookies.remove('access_token');
        localStorage.clear();
        Cookies.remove('user_role');
        toast.success('Successfully Logged Out!')
        window.location = "/";
    }

    return (
        <Card className="side-bar">
            <NavLink to={`${APP_PREFIX}/dashboard`}>
                <li><img src={Dashboard} alt="" />Dashboard</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/rides`}>
                <li><img src={Rides} alt="" />Rides</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/users`}>
                <li><img src={User} alt="" />Users</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/drivers`}>
                <li><img src={Car} alt="" />Drivers</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/live-tracking`}>
                <li ><img src={MapIcon} style={{ width: "2.8rem", marginLeft: "-8px", marginRight: "20px" }} alt="" />Live Tracking</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/vehicles`}>
                <li><img src={Vehicle} alt="" />Vehicles</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/subscription`}>
                <li><img src={Subs} alt="" />Subscription</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/benifits`}>
                <li><img src={Ben} alt="" />Benefits</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/events`}>
                <li><img src={Soft} alt="" />Events</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/reports`}>
                <li><img src={Report} alt="" />Reports</li>
            </NavLink>
            {localStorage.getItem("user_role") === "ADMIN" && <NavLink to={`${APP_PREFIX}/staffs`}>
                <li><img src={Staff} alt="" />Staff</li>
            </NavLink>}
            <NavLink to={`${APP_PREFIX}/profiles`}>
                <li><img src={Profile} alt="" />Profile</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/enquiry`}>
                <li><img src={Ben} alt="" />Enquiries</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/feedbacks`}>
                <li><img src={Soft} alt="" />Feedbacks</li>
            </NavLink>
            <NavLink to={`${APP_PREFIX}/settings`}>
                <li><img src={Setting} alt="" />Settings</li>
            </NavLink>
            <NavLink to="/">
                <li className="footer" onClick={() => handleLogout()}><img src={Logout} alt="" />Logout</li>
            </NavLink>
        </Card>
    )
}

export default SideBar;