import axios from "axios";
import { EndPoints } from "./EndPoints";
// import Cookies from "js-cookie";
import { setDetails } from "services/auth";
import { base_url } from "./EnvConfig";

const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;

const instance = axios.create({
    baseURL: base_url,
    timeout: TIMEOUT,
});



instance.interceptors.request.use(function (config) {
    return config;
});
instance.interceptors.response.use(
    // (response) => response,
    // (error) => Promise.reject(error)
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location = "/";
        }
        else {
            console.error(error);
        }

        return Promise.reject(error);
    }
);

const ZunoApi = {
    get: async (key, params = {}, id) => {
        const url = EndPoints[key].url + (id ? `/${id}` : "");
        async function apiCall() {
            return instance.get(url, {
                params,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    getId: async (key, params = {}, id) => {
        const url = EndPoints[key].url + id;
        async function apiCall() {
            return instance.get(url, {
                params,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    deleteImg: async (key, params = {}, id) => {
        const url = EndPoints[key].url + (id ? `${id}` : "");
        async function apiCall() {
            return instance.get(url, {
                params,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }
        const getdata = await apiCall();
        return getdata?.data;
    },
    deleteDocsImg: async (key, id, id1) => {
        const url = `${EndPoints[key].url}?driverId=${id}&type=${id1}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    getId: async (key, id) => {
        const url = `${EndPoints[key].url}${id}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    getFeedback: async (key, id, id1) => {
        const url = `${EndPoints[key].url}?page=${id}&type=${id1}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    getTrial: async (key, id, id1) => {
        const url = `${EndPoints[key].url}?vehicleId=${id}&days=${id1}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    getPosition: async (key, id) => {
        const url = `${EndPoints[key].url}?mode=${id}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        if (getdata?.data) {
            setDetails(getdata?.data);
        }
        return getdata?.data;
    },
    getStatus: async (key, id, id1) => {
        const url = `${EndPoints[key].url}?statusId=${id}&vehicleId=${id1}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        if (getdata?.data) {
            setDetails(getdata?.data);
        }
        return getdata?.data;
    },
    getX: async (key, page) => {
        const url = EndPoints[key].url + `?page=${page}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    getImgPath: async (id) => {
        // =profile-img/525b6473-ce21-4f35-b275-07fc24706afc/file_2022-08-16-21-46_1.jpg'
        const base = `https://api.zunocabs.com/getFileStream?path=${id}`;
        async function apiCall() {
            return instance.get(base, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    getNotify: async (key) => {
        const url = EndPoints[key].url;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const getdata = await apiCall();
        return getdata?.data;
    },
    updateNotification: async (key, id) => {
        const url = `${EndPoints[key].url}?notificationIds=${id}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }
        const getdata = await apiCall();
        if (getdata?.data) {
            setDetails(getdata?.data);
        }
        return getdata?.data;
    },
    pubileGet: async (key, id) => {
        const url = EndPoints[key].url + (id ? `/${id}` : "");
        async function apiCall() {
            return instance.get(url);
        }
        const getdata = await apiCall();
        return getdata?.data;
    },
    pubileGetById: async (key, id) => {
        const url = EndPoints[key].url + id;
        async function apiCall() {
            return instance.get(url);
        }
        const getdata = await apiCall();
        return getdata?.data;
    },
    pubileDownload: async (key, path) => {
        const url = `${EndPoints[key].url}?filePath=${path}`;
        async function apiCall() {
            return instance.get(url);
        }
        const getdata = await apiCall();
        return getdata?.data;
    },
    pubileList: async (key, { }, page) => {
        const url = EndPoints[key].url + `?page=${page}`;
        async function apiCall() {
            return instance.get(url, {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }
        const getdata = await apiCall();
        return getdata?.data;
    },
    post: async (key, data = {}, id = "") => {
        const url = EndPoints[key].url + (id ? `/${id}` : "");
        async function apiCall() {
            return instance({
                method: "POST",
                url: url,
                data: data,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const postData = await apiCall();
        return postData?.data;
    },
    postNotification: async (key, data = {}, id = "") => {
        const url = EndPoints[key].url + `?role=${id}`;
        async function apiCall() {
            return instance({
                method: "POST",
                url: url,
                data: data,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const postData = await apiCall();
        return postData?.data;
    },
    postPage: async (key, data = {}, page = "") => {
        const url = page ? EndPoints[key].url + `?page=${page}` : EndPoints[key].url;
        async function apiCall() {
            return instance({
                method: "POST",
                url: url,
                data: data,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const postData = await apiCall();
        return postData?.data;
    },
    postText: async (key, data = {}, id = "") => {
        const url = EndPoints[key].url + (id ? `/${id}` : "");
        async function apiCall() {
            return instance({
                method: "POST",
                url: url,
                data: data,
                headers: {
                    // 'Content-Type': 'multipart/form-data',
                    "content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const postData = await apiCall();
        return postData?.data;
    },
    patch: async (key, data = {}, id = "") => {
        const url = EndPoints[key].url + id;
        async function apiCall() {
            return instance({
                method: "",
                url: url,
                data: data,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const patchData = await apiCall();
        return patchData?.data;
    },
    Img: async (key, data = {}, id = "") => {
        const url = EndPoints[key].url + (id ? `/${id}` : "");
        async function apiCall() {
            return instance({
                method: "DELETE",
                url: url,
                data: data,
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            });
        }

        const deleteData = await apiCall();
        return deleteData?.data;
    }
};

export default ZunoApi;
