import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import "antd/dist/antd.css";
import { Link } from "react-router-dom";
import moment from 'moment';
import PrivateImage from "../image";

class NotifyBody extends React.Component {
    constructor() {
        super();
        this.state = {
            show: false,
            target: null,
        };
        this.myRef = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.readAllData = this.readAllData.bind(this);
        this.viewItem = this.viewItem.bind(this);
    }
    handleClick = (event) => {
        this.setState({
            show: !this.state.show,
            target: event.target
        })
    };
    readAllData() {
        if (this.props.data !== undefined && this.props.data?.length !== 0) {
            let ids = this.props.data?.map((item) => item.notificationId);
            this.props.update(ids);
        }
        this.setState({
            show: false
        });
    }
    viewItem(item) {
        if (item.type === 2) {
            window.location = `#/app/drivers/info/${item.id}`;
        } if (item.type === 3) {
            window.location = `#/app/rides`;
        } if (item.type === 4) {
            window.localtion = `#/app/vehicles/list`;
        } if (item.type === 5) {
            window.location = `#/app/staffs/edit/${item.id}`;
        } if (item.type === 1) {
            window.location = `#/app/users/info/${item.id}`;
        }
        this.setState({
            show: false
        });
        let arr = [];
        arr.push(item.notificationId);
        this.props.update(arr);
    }
    render() {
        const { show, target, ref } = this.state;
        const { data } = this.props;
        return (
            <div ref={this.myRef} className="filter-background" >
                <button type="button" className="icon-button " onClick={this.handleClick} >
                    <span className="material-icons"><i className="fas fa-bell"></i></span>
                    {data?.length !== 0 ? <span className="icon-button__badge">{data?.length}</span> : <></>}
                </button>
                <Overlay
                    show={show}
                    target={target}
                    placement="bottom"
                    container={ref}
                    containerPadding={40}
                    className="notification-pop-up"
                >
                    <Popover id="popover-contained" style={{ minWidth: "23%" }}>
                        <Popover.Body >
                            <div className="scroll-page">
                                <div className="notify_data" style={{ paddingBottom: "30px" }}>
                                    <h3>Notifications</h3>
                                    <p onClick={this.readAllData} style={{ color: "#2D8FF1", marginTop: "5px", cursor: "pointer" }}>Mark all as read</p>
                                </div>
                                {data !== undefined && data !== null && data?.length !== 0 ? data.map((item, i) => {
                                    return (
                                        <>
                                            {item.type === 4 ? <Link to="/app/vehicles/list" style={{ color: "black" }}>
                                                <div key={i} className="notify_msg" onClick={() => this.viewItem(item)}>
                                                    <PrivateImage className="notify_msg img" privateUrl={item.image} />
                                                    {/* <img src={item.image && item.image !== null ? S3_ROOT + item.image : Logo} alt="" /> */}
                                                    <p>{item?.message}</p>
                                                    <p className="time-ago">{moment(item.updatedAt).fromNow()}</p>
                                                </div>
                                            </Link>
                                                : <div key={i} className="notify_msg" onClick={() => this.viewItem(item)}>
                                                    <PrivateImage className="notify_msg img" privateUrl={item.image} />
                                                    {/* <img src={item.image && item.image !== null ? S3_ROOT + item.image : Logo} alt="" /> */}
                                                    <p>{item?.message}</p>
                                                    <p className="time-ago">{moment(item.updatedAt).fromNow()}</p>
                                                </div>}
                                        </>
                                    )
                                }) : <p>No data</p>}
                            </div>
                        </Popover.Body>
                    </Popover>
                </Overlay>
            </div >

        )
    }
}

export default NotifyBody;