import { config } from "configs/EndPoints";
import moment from "moment";
import axios from "axios";

export const apiKey = process.env.REACT_APP_MAP_API_KEY;

const changeDateFormat = (date) =>
    moment(date).format("DD MMM YYYY , hh:mm A");

const notificationFormat = (date) =>
    moment(date).format("hh:mm A");

const timeFormat = (date) =>
    moment(date).format("hh A");

const dateFormat = (date) =>
    moment(date).format("DD MMM YYYY");

const filterDateFormat = (date) =>
    moment(date).format("YYYY-MM-DD");

const driverfilterDateFormat = (date) =>
    moment(date).add(1, 'days').format("YYYY-MM-DD");

const displayCount = (val = 0) => {
    return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}
const getImages = async (val) => {
    const base = {
        headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}`, }
    };
    try {
        const response = await axios.get(`${config.url}/getFileStream?path=${val}`, base);
        return response;
    } catch (error) {
        if (error.response) {
            return error.response;
        } else if (error.request) { // no response
            return error.request;
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
};


export { changeDateFormat, filterDateFormat, displayCount, dateFormat, timeFormat, getImages, notificationFormat, driverfilterDateFormat };
