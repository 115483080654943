const EndPoints = {
    //  Dashboard Api Start

    fetchDashboardDetails: {
        url: "/staff/getMainDashBoardDetails"
    },

    fetchIndividualDashBoardDetails: {
        url: "/staff/getIndividualDashBoardDetails"
    },

    //  Dashboard Api End

    // Driver Api Start

    fetchDriversList: {
        url: "/staff/getRegisteredDrivers",
    },

    fetchDriver: {
        url: `/admin/getDriverById`,
    },

    driverUpdate: {
        url: "/admin/updateDriverList",
    },

    changeDriverStatus: {
        url: "/admin/setRegistrationStatusForDriver",
    },

    changeVehicleStatus: {
        url: "/admin/setRegistrationStatusForVehicle",
    },

    changeVehicleSubscriptionStatus: {
        url: "/admin/setSubscriptionStatus",
    },

    changeDriverPassword: {
        url: "/admin/changeDriverPassword/"
    },

    fetchDriverVehicle: {
        url: "/public/getDriverVehicleList"
    },

    addDriverVehicle: {
        url: "/public/addNewVehicleForDriver"
    },

    updateDriverVehicle: {
        url: "/public/updateVehicleForDriver"
    },

    fetchDriverVehicleById: {
        url: "/admin/getDriverVehicleById"
    },

    deleteDocumentById: {
        url: "/admin/deleteDocumentById"
    },

    uploadDriverVehicleImage: {
        url: "/public/upload/vehicle-img"
    },
    uploadDriverRcImg: {
        url: "/public/upload/rc-img"
    },
    uploadNotificationImg: {
        url: "/public/upload/public-assets"
    },
    stateListId: {
        url: "/public/dropDown/getStateList"
    },
    districtListId: {
        url: "/public/dropDown/getDistrictList"
    },

    deleteAadhaarImg: {
        url: "/admin/deleteImagesForDriver"
    },
    deleteVehicleImg: {
        url: "/admin/deleteVehicleImage?vehicleId="
    },
    deleteVehicleTypeImg: {
        url: "/admin/deleteVehicleTypeImage?vehicleTypeId="
    },

    deleteAdImg: {
        url: "/admin/deleteAdImage?advertisementId="
    },

    deleteRcImg: {
        url: "admin/deleteRcImageForVehicle?vehicleId="
    },

    // Driver Api End

    // User Api Start

    fetchUsersList: {
        url: "/staff/getRegisteredUsers",
    },
    fetchabandonedUsersList: {
        url: "/admin/abandonedUsersList",
    },

    fetchUser: {
        url: "/admin/getDetails",
    },

    userUpdate: {
        url: "/admin/editRegisteredUser",
    },

    changeUserPassword: {
        url: "/admin/changePassword"
    },

    // User Api End

    // Staff Api Start

    fetchStaffList: {
        url: '/staff/getRegisteredUsers',
    },

    fetchaddStaffList: {
        url: '/admin/addOrEditStaff',
    },

    fetchStaffbyId: {
        url: '/admin/getDetails',
    },

    fetchStaffChangePassword: {
        url: '/staff/changePassword',
    },

    deleteProfileImage: {
        url: 'admin/deleteProfileImage?userId='
    },

    // Staff Api End

    // vehicles Api Start

    fetchVehiclesList: {
        url: '/staff/vehicleList'
    },

    getSubscriptionHistory: {
        url: "staff/getSubscriptionHistory?vehicleId="
    },

    // vehicles Api End

    // Reports Api Start

    fetchReportsList: {
        url: '/admin/getReportList'
    },

    fetchReportsListCsv: {
        url: "/admin/getReportListCSV"
    },

    // Reports Api End

    // Trips Api Start

    fetchTripsList: {
        url: "/admin/getTripList"
    },

    fetchTripsListCsv: {
        url: "/admin/getTripListCSV"
    },

    fetchSosList: {
        url: "/admin/getSosList"
    },

    fetchSosId: {
        url: "/admin/getSosDetails?sosId="
    },

    // Trips Api End

    // Feedbacks Api Start

    fetchDriverFeedBack: {
        url: "admin/viewFeedbacks"
    },

    getFeedbackById: {
        url: "admin/getFeedbackById?feedbackId="
    },

    getRideById: {
        url: "admin/getRideDetails?rideId="
    },

    // Feedbacks Api End

    // Subscription Api Start

    getSubscriptionList: {
        url: `staff/getSubscriptionList`
    },

    getSubscriptionPageCounts: {
        url: `staff/getSubscriptionPageCounts`
    },

    editSubscriptionDetails: {
        url: `staff/editSubscriptionPlanDetails`
    },

    getSubscriptionDetails: {
        url: `staff/getSubscriptionPlanDetails`
    },

    extendTrialForNewUsers: {
        url: `staff/extendExpiryForTrialVehicles?days=`
    },

    extendTrialForVehicle: {
        url: `staff/extendVehicleExpiry`
    },

    // Subscription Api End

    // Settings Module Api Start

    fetchVehicleTypeList: {
        url: '/public/getVehicleTypeList'
    },

    addVehicleType: {
        url: "/admin/addOrEditVehicleType"
    },

    fetchPositionDropdown: {
        url: "/public/getVehicleTypeDropdown"
    },

    fetchVehicleTypeById: {
        url: "/admin/getVehicleTypeById"
    },

    fetchVehicleTypeDropDown: {
        url: "/public/dropdownVehicleTypeForActiveStatus"
    },

    fetchVehicleBrandDropDown: {
        url: "/public/dropdownVehicleBrandForActiveStatus"
    },

    fetchVehicleModelDropDown: {
        url: "/public/dropdownVehicleModelForActiveStatus"
    },

    fetchVehicleBrandList: {
        url: "/public/getVehicleBrandList"
    },

    addVehicleBrand: {
        url: "/admin/addOrEditVehicleBrand",
    },

    fetchVehicleBrandById: {
        url: "/admin/getVehicleBrandById"
    },

    fetchVehicleModelList: {
        url: "/public/getVehicleModelList"
    },

    addVehicleModel: {
        url: "/admin/addOrEditVehicleModel"
    },

    fetchVehicleModelById: {
        url: "/admin/getVehicleModelById"
    },

    fetchFareDetailsList: {
        url: 'public/getFareDetails'
    },

    addFareDetails: {
        url: "/admin/addOrUpdateFareDetails"
    },

    fetchFareDetailsById: {
        url: "/admin/getFareDetailById"
    },

    fetchCountryList: {
        url: "/public/getCountryList"
    },

    fetchCountryDropdown: {
        url: "/public/dropDown/getCountryList"
    },

    fetchCountryById: {
        url: "/admin/getCountryById"
    },

    addCountryDetails: {
        url: "/admin/addOrEditCountry"
    },

    fetchStateList: {
        url: "/public/getStateList"
    },

    fetchStateDropdown: {
        url: "/public/dropDown/getStateList"
    },

    fetchStateById: {
        url: "/admin/getStateById"
    },

    addStateDetails: {
        url: "/admin/addOrEditState"
    },

    fetchCityList: {
        url: "/public/getDistrictList"
    },

    fetchCityById: {
        url: "/admin/getDistrictById"
    },

    addCityDetails: {
        url: "/admin/addOrEditDistrict"
    },

    fetchAdvertisementList: {
        url: "/staff/getAdvertisementList"
    },

    fetchAdvertisementById: {
        url: "/admin/getAdvertisementById"
    },

    addAdvertisement: {
        url: "/admin/addOrEditAdvertisement"
    },

    addRewardPoints: {
        url: "/staff/rewardConfiguration"
    },
    fetchRewards: {
        url: "/staff/getRewardsConfiguration"
    },
    fetchPushNotifications: {
        url: "/admin/getPagedAdminNotifications"
    },
    addPushNotification: {
        url: "/admin/addOrEditAdminNotification"
    },
    fetchNotificationById: {
        url: "/admin/getAdminNotificationById?notificationId="
    },
    fetchFaq: {
        url: "/admin/getPagedFrequentlyAskedQuestions"
    },
    fetchFaqById: {
        url: "/public/getFrequentlyAskedQuestionById?questionId="
    },
    addFaq: {
        url: "/admin/addOrEditFrequentlyAskedQuestions"
    },


    // Settings Module Api End


    // Profile Api Start

    profileUpdate: {
        url: '/admin/addOrEditStaff',
    },
    getProfile: {
        url: "/staff/getAdminOrStaffDetails",
    },
    changeAdminPassword: {
        url: "/staff/changePassword"
    },

    // Profile Api End

    // Events Api Start

    getEventsWinners: {
        url: "/admin/getWinnersList"
    },

    getEventsWinnersCsv: {
        url: "/admin/getWinnersListCSV"
    },

    fetchTopRidersList: {
        url: "admin/getTopRidersList"
    },

    fetchTopRidersCsv: {
        url: "/admin/getTopRidersListCSV"
    },

    // Events Api End

    // Benefits Api Start

    fetchReferralList: {
        url: "/admin/getReferralList"
    },

    fetchReferralListCsv: {
        url: "/admin/getReferralListCSV"
    },

    fetchInsuranceList: {
        url: "/admin/getInsuranceList"
    },

    updateInsurance: {
        url: "admin/updateInsurance"
    },


    delectInsuranceDocument: {
        url: "admin/deleteInsuranceDocById"
    },

    fetchInsuranceById: {
        url: "/admin/getInsuranceById"
    },

    fetchInsuranceListCsv: {
        url: "/admin/getInsuranceListCSV"
    },

    fetchRenewalList: {
        url: "/admin/getInsuranceRenewalHistory"
    },

    fetchRenewalListCsv: {
        url: "/admin/getInsuranceRenewalHistoryCSV"
    },

    fetchAppShareCountList: {
        url: "/admin/getAppShareList"
    },

    fetchUserAppShareList: {
        url: "/admin/getUserAppShareList"
    },

    fetchDriverAppShareList: {
        url: "/admin/getDriverAppShareList"
    },

    fetchRedeemList: {
        url: "/admin/getRedemptionList"
    },

    changeRedemptionStatus: {
        url: "/admin/redemptionStatusChange"
    },

    // Benefits Api End

    // Notifications Api Start 

    fetchNotificationsList: {
        url: "/staff/getCmsNotifications"
    },

    updateCmsNotifications: {
        url: '/staff/updateViewedCmsNotifications'
    },

    downloadFile: {
        url: "public/fileDownload"
    },

    // 'https://uatapi.zunocabs.com/?role=3
    updateInstantNotification: {
        url: '/admin/sendInstantNotifications'
    },

    // Counts 

    userAndStaffCount: {
        url: "/admin/getUserAndStaffCount"
    },
    driverCount: {
        url: "/admin/getDriverCount"
    },
    vehicleCount: {
        url: "/admin/getVehicleCount"
    },
    rideCount: {
        url: "/admin/getRideCount"
    },

    // Enquiry

    enquiryList: {
        url: "/admin/getContactList"
    },

    getImgFilePath: {
        url: "getFileStream"
    }



};
const config = {
    url: "https://api.zunocabs.com"
};

export { EndPoints, config };